import React, { ReactNode, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';

import styled from 'styled-components';
import { mobile, tablet } from '@components/constants/constants';
import { useWidthScreens } from '@hooks/useWidthScreens';

interface IButtonMain {
  buttonText: string;
  sizeText?: string;
  colorType?: 'primary_black' | 'secondary_white' | 'primary_white' | 'secondary_black';
  buttonSize?: 'large' | 'normal' | 'medium' | 'small';
  className?: string;
  clickEvent?: () => void;
  type?: 'submit' | 'reset' | 'button' | 'delete' | 'edit';
  svgIconLeft?: ReactNode;
  svgIconRight?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSquare?: boolean;
}

export default function ButtonMain({
  buttonText,
  clickEvent,
  isDisabled,
  svgIconLeft,
  type,
  sizeText = '18px',
}: IButtonMain) {
  const { isMobile } = useWidthScreens();

  const currentColor = useMemo(() => {
    switch (type) {
      case 'delete':
        return colorsVar.redDelete;
      case 'edit':
        return colorsVar.textColor;
      default:
        return colorsVar.white;
    }
  }, [type]);
  return (
    <ButtonWrap onClick={!isDisabled ? clickEvent : undefined} isDisabled={isDisabled} type={type}>
      {svgIconLeft && <div className="mr-4">{svgIconLeft}</div>}
      <LineText
        className="justify-center"
        fontSize={sizeText}
        color={currentColor}
        bold={isMobile ? '400' : '400'}
        lineHeight="24px"
      >
        <FormattedMessage id={buttonText} />
      </LineText>
    </ButtonWrap>
  );
}

const ButtonWrap = styled.div<{ isDisabled?: boolean; type?: string }>`
  display: flex;
  width: 100%;
  height: 43px;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${colorsVar.greenBase};
  color: ${colorsVar.white};
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: ${colorsVar.greenHover};
    transition: all 0.5s ease;
  }
  &:active {
    background-color: ${colorsVar.greenActive};
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
    background-color: ${colorsVar.grey4};
    cursor: not-allowed;
    &:hover {
    background-color: ${colorsVar.grey4};
    transition: all 0.5s ease;
   }
  `}
  ${({ type }) => {
    if (type === 'delete')
      return `
    background-color: transparent;
    border: 1px solid ${colorsVar.redDelete};
    &:hover {
    background-color: #f5eae9;
    transition: all 0.5s ease;
   }
  `;
    if (type === 'edit')
      return `
    background-color: transparent;
    border: 1px solid ${colorsVar.grey4};
    &:hover {
    background-color: ${colorsVar.grey1};
    transition: all 0.5s ease;
   }
  `;
  }}

  @media screen and (max-width: ${tablet}px) {
    height: 48px;
  }
  @media screen and (max-width: ${mobile}px) {
    height: 43px;
  }
`;
