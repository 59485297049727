import { IRenderLine } from 'interfaces/stats.interfaces';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import './lineStatChart.scss';
import { colorsVar } from '@components/constants/colorVariables';

interface LineChartProps {
  data: any;
  externalTooltip: any;
  renderLine?: IRenderLine[];
  fill?: string;
  pointXaxis?: string;
  dot?: any;
  customTick?: any;
}

export const LineStatChart = ({
  data,
  externalTooltip,
  renderLine,
  fill,
  dot,
  pointXaxis,
  customTick,
}: LineChartProps) => {
  const formatYAxis = (tick: any) => {
    if (tick >= 100000) {
      return `${Math.round(tick / 1000)}k`;
    }
    return Number.isInteger(tick) ? tick : '';
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ right: 47, left: 0, bottom: 15, top: 7 }} width={1000} height={350}>
          <CartesianGrid vertical={false} height={350} />
          <XAxis
            angle={customTick ? 0 : -25}
            tick={fill ? { fill: `${fill}` } : false}
            tickMargin={5}
            textAnchor={customTick ? 'middle' : 'end'}
            fontSize={11.5}
            fontFamily={'Roboto'}
            dataKey={pointXaxis}
            tickLine={false}
            axisLine={false}
            padding={{ left: 3 }}
          />
          <YAxis
            tick={{ fill: colorsVar.textColor }}
            tickMargin={10}
            fontFamily={'Roboto'}
            fontSize={14}
            fontWeight={400}
            tickFormatter={formatYAxis}
            tickLine={false}
            orientation="left"
            axisLine={false}
            padding={{ top: 0, bottom: 0 }}
          ></YAxis>
          <Tooltip content={externalTooltip} />
          {renderLine &&
            renderLine.map((line) => (
              <Line
                key={line.dataKey}
                dataKey={line.dataKey}
                strokeWidth={2}
                stroke={line.fill}
                fill={line.fill}
                dot={dot}
                activeDot={{ r: 9 }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
