export const DOGMA_USER = 'DOGMA_USER';
export const SUPERVISOR = 'supervisor';
export const HAVE_EMPLOYEES = 'haveEmployees';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const LIMIT_PAGE = 20;

//  ================ URLs =============
export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
export const SERVICE_URL_V2 = process.env.REACT_APP_SERVICE_URL_V2;
export const SESSIONS_URL = process.env.REACT_APP_SESSIONS_URL;
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
export const ASSISTANT_SETTING_URL = process.env.REACT_APP_ASSISTANT_SETTING_URL;
export const DASHBOARD_SERVICE_URL = process.env.REACT_APP_DASHBOARD_SERVICE_URL;

//  ================ spaceIds =============
export const SPACE_ID_DOGMA = process.env.REACT_APP_SPACE_ID_DOGMA;

// export const supervisor = true; //:TODO ============= SUPERVISOR ======
export const isFullVersion = true;

export const tablet = 1200;
export const mobile = 700;
