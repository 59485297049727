import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { IconWrap } from './NavBar.Exit';
import { Container } from './NavBar.Avatar';
import { uniqueId } from 'lodash';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';
import { navbarAdmin, navbarSupervisorWithEmployees } from './constants';

interface INavBarLink {
  hidden: boolean;
  haveEmployees: boolean;
  supervisor: boolean;
}

const NavBarLink: FC<INavBarLink> = (props) => {
  const navbarSupervisor = props.haveEmployees ? navbarSupervisorWithEmployees : [];
  const mapNavBar = props.supervisor ? navbarSupervisor : navbarAdmin;

  return (
    <>
      {mapNavBar.map((linkLine) => (
        <NavLink to={linkLine.path} key={uniqueId(linkLine.title)}>
          {({ isActive }) => (
            <ContainerLink className="flex" active={isActive}>
              <IconWrap className="flex">{isActive ? linkLine.iconActive : linkLine.icon}</IconWrap>
              <LineText fontSize={'16px'}>
                <FormattedMessage id={linkLine.title} />
              </LineText>
            </ContainerLink>
          )}
        </NavLink>
      ))}
    </>
  );
};

export default NavBarLink;

const ContainerLink = styled(Container)<{ active: boolean }>`
  padding-left: 14px;
  background-color: ${(props) => (props.active ? colorsVar.grey1 : null)};
  border-radius: 3px;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: ${(props) => (props.active ? null : colorsVar.violetHover)};
    transition: background-color 0.5s ease;
  }
`;
