import React, { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';
import { useGetDivisionsListQuery } from '@store/user/user.api';
import { debounce } from 'lodash';
import { ExtendedCheckSubdivision, IDivisions } from 'interfaces/user.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import dayjs from 'dayjs';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import { mobile, tablet } from '@components/constants/constants';
import { SelectDateReports } from './SelectDateReports';
import DropDownReports from './DropDownReports';
import { ICurrentPeriod } from 'interfaces/stats.interfaces';

interface IFiltersBlock {
  setActiveUserParams: (value: string) => void;
  setCurrentPeriod: (value: ICurrentPeriod) => void;
  setIdsList: (value: (string | undefined)[]) => void;
}

export const FiltersBlock: FC<IFiltersBlock> = ({
  setActiveUserParams,
  setCurrentPeriod,
  setIdsList,
}: IFiltersBlock) => {
  const { isWideScreen } = useWidthScreens();

  const [checkedDivision, setCheckedDivision] = useState<ExtendedCheckSubdivision[]>([]);
  const [checkedDepartments, setCheckedDepartments] = useState<ExtendedCheckSubdivision[]>([]);
  const [searchSubdivision, setSearchSubdivision] = useState('');
  const [searchDepartment, setSearchDepartment] = useState('');
  const [startDate, setsStartDate] = useState(dayjs().add(-10, 'day'));
  const [endDate, setsEndDate] = useState(dayjs());
  const [paramsString, setParamsString] = useState<string | null>(null);

  const { currentData: divisionsList, isLoading, isFetching } = useGetDivisionsListQuery(paramsString, {
    skip: paramsString === null,
  });

  const debounceValueCommon = debounce((value: string, action: Dispatch<React.SetStateAction<string>>) => {
    action(value);
    const paramsString = new URLSearchParams({
      query: value,
    }).toString();
    setParamsString(paramsString);
  }, 2000);

  useEffect(() => {
    const paramsString = new URLSearchParams({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    }).toString();
    setActiveUserParams(paramsString);
    setCurrentPeriod({ startDate, endDate });
  }, []);

  function handleSearchSubdivision(value: string) {
    debounceValueCommon(value, setSearchSubdivision);
  }
  function handleSearchDepartment(value: string) {
    debounceValueCommon(value, setSearchDepartment);
  }

  function handleCheckedSubdivision(value: Partial<IDivisions>) {
    setCheckedDepartments([]);
    if (checkedDivision.some((item) => item.id === value.id)) {
      setCheckedDivision(checkedDivision.filter((item) => item.id !== value.id));
      return;
    }
    setCheckedDivision([{ ...value, isActive: true }]);
  }
  function handleCheckedDepartment(value: Partial<IDivisions>) {
    if (checkedDepartments.some((item) => item.id === value.id)) {
      setCheckedDepartments(checkedDepartments.filter((item) => item.id !== value.id));
      return;
    }
    setCheckedDepartments([...checkedDepartments, { ...value, isActive: true }]);
  }

  function handleStartDate(event: any) {
    setsStartDate(dayjs(event));
  }
  function handleEndDate(event: any) {
    setsEndDate(dayjs(event));
  }
  function onSave() {
    const paramsString = new URLSearchParams({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    }).toString();
    setActiveUserParams(paramsString);
    setCurrentPeriod({ startDate, endDate });
    if (checkedDepartments.length) {
      setIdsList(checkedDepartments?.map((item) => item.id));
    } else {
      setIdsList(checkedDivision?.map((item) => item.id));
    }
  }

  function cleanFilters() {
    setCheckedDivision([]);
    setCheckedDepartments([]);
    setsStartDate(dayjs());
    setsEndDate(dayjs());
  }

  return (
    <ContentWrap>
      <FilterItemWrap>
        <DropDownReports
          handleCheck={handleCheckedSubdivision}
          textLabel="subdivision"
          dropList={divisionsList ?? []}
          checkedList={checkedDivision}
          textHeader={checkedDivision?.[0]?.name ?? ''}
          handleSearch={handleSearchSubdivision}
          isLoading={isFetching}
          isLoadingDep={isFetching}
          searchValue={searchSubdivision}
        />
      </FilterItemWrap>
      <FilterItemWrap>
        <DropDownReports
          handleCheck={handleCheckedDepartment}
          textLabel="departments"
          dropList={checkedDivision[0]?.subDepartments ?? []}
          checkedList={checkedDepartments}
          textHeader={checkedDepartments?.[0]?.name ?? ''}
          handleSearch={handleSearchDepartment}
          isLoading={false}
          searchValue={searchDepartment}
        />
      </FilterItemWrap>
      <FilterItemWrap>
        <SelectLine>
          <SelectDateReports
            type="input"
            onChange={handleStartDate}
            id="firstEntryDateStartRange"
            value={startDate.format('DD.MM.YY')}
            position={'right'}
            textLabel="period"
          />
          <SelectDateReports
            type="input"
            onChange={handleEndDate}
            id="firstEntryDateStartRange"
            value={endDate.format('DD.MM.YY')}
            position={'left'}
            textLabel=""
          />
        </SelectLine>
      </FilterItemWrap>
      <ButtonWrap>
        <ButtonMain clickEvent={onSave} buttonText="apply" type="button" sizeText="14px" />
        <RenderWithCondition condition={!isWideScreen}>
          <ButtonMain clickEvent={cleanFilters} buttonText="clearFilter" type="delete" sizeText="14px" />
        </RenderWithCondition>
      </ButtonWrap>
      <RenderWithCondition condition={isWideScreen}>
        <ButtonMain clickEvent={cleanFilters} buttonText="clearFilter" type="delete" sizeText="14px" />
      </RenderWithCondition>
    </ContentWrap>
  );
};

const ButtonWrap = styled.div`
  display: flex;
  gap: 4%;
`;
const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr;
  padding: 15px 25px;
  gap: 10px;
  border-top: 1px solid ${colorsVar.grey1};
  margin-top: -27px;
  @media screen and (max-width: ${tablet}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 7px;
  }
  @media screen and (max-width: ${mobile}px) {
    margin-top: 17px;
    height: 350px;
    border-top: 1px solid transparent;
  }
`;
const FilterItemWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectLine = styled.div`
  display: grid;
  grid-template-columns: 268px 268px;
  align-items: start;
  gap: 10px;
  @media screen and (max-width: ${tablet}px) {
  }
`;
