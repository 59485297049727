import React, { FC } from 'react';
import { ReactComponent as StatsIcon } from '@assets/icons/stats.svg';
import { ReactComponent as UserIcon } from '@assets/icons/user.svg';
import { ReactComponent as AssistIcon } from '@assets/icons/assist__green.svg';
import { ReactComponent as GroupUsersIcon } from '@assets/icons/group-users.svg';
import { ReactComponent as GroupIcon } from '@assets/icons/groups.svg';
import { ReactComponent as ReportsIcon } from '@assets/icons/reports.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/key.svg';

import { colorsVar } from '../../constants/colorVariables';
import { regexPhone } from '../../screens/auth/constants';

export const navbarAdmin = [
  {
    title: 'statistic',
    path: '/',
    icon: <StatsIcon fill={colorsVar.grey4} />,
    iconActive: <StatsIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'reports',
    path: 'reports',
    icon: <ReportsIcon fill={colorsVar.grey4} width={35} hight={35} />,
    iconActive: <ReportsIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'employees',
    path: '/employees',
    icon: <UserIcon fill={colorsVar.grey4} />,
    iconActive: <UserIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'assistants',
    path: '/assistants',
    icon: <AssistIcon fill={colorsVar.grey4} />,
    iconActive: <AssistIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'groups',
    path: 'groups',
    icon: <GroupIcon fill={colorsVar.grey4} />,
    iconActive: <GroupIcon fill={colorsVar.greenBase} />,
  },

  {
    title: 'thirdParty',
    path: 'others',
    icon: <GroupUsersIcon fill={colorsVar.grey4} />,
    iconActive: <GroupUsersIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'administrators',
    path: 'admin',
    icon: <KeyIcon fill={colorsVar.grey4} />,
    iconActive: <KeyIcon fill={colorsVar.greenBase} />,
  },
];

export const navbarSupervisorWithEmployees = [
  {
    title: 'statistic',
    path: '/',
    icon: <StatsIcon fill={colorsVar.grey4} />,
    iconActive: <StatsIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'employees',
    path: '/employees',
    icon: <UserIcon fill={colorsVar.grey4} />,
    iconActive: <UserIcon fill={colorsVar.greenBase} />,
  },
];
