import { colorsVar } from '@components/constants/colorVariables';
import { IReportChartsConfig } from 'interfaces/stats.interfaces';

export const mapChartsConfig: IReportChartsConfig[] = [
  {
    mapChartData: [
      {
        activeLines: [
          { dataKey: 'totalNumberActiveUsers', fill: colorsVar.greenLine },
          { dataKey: 'totalNumberUniqueActiveUsers', fill: colorsVar.blueLine },
        ],
        textTitle: 'totalAmountActiveUsers',
        textLeftSubTitle: 'uniqueVisitors',
        textRightSubTitle: 'totalActivities',
        leftDataTitleKey: 'totalNumberUniqueActiveUsers',
        rightDataTitleKey: 'totalNumberActiveUsers',
        leftCallback: arrayUniqueData,
        rightCallback: commonSumData,
        onlyOneLine: true,
      },
    ],
  },
  {
    mapChartData: [
      {
        activeLines: [{ dataKey: 'totalNumberBusinessGoals', fill: colorsVar.violetLine }],
        textTitle: 'businessGoals',
        textLeftSubTitle: 'totalBusinessGoals',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberBusinessGoals',
        rightDataTitleKey: '',
        leftCallback: commonSumData,
      },
      {
        activeLines: [{ dataKey: 'totalNumberUsersSetGoals', fill: colorsVar.orangeLine }],
        textTitle: '',
        textLeftSubTitle: 'amountUsersCreatedBusinessGoals',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberUsersSetGoals',
        rightDataTitleKey: '',
        leftCallback: arrayUniqueData,
      },
    ],
  },
  {
    mapChartData: [
      {
        activeLines: [{ dataKey: 'totalNumberBusinessTasks', fill: colorsVar.redLine }],
        textTitle: 'businessTasks',
        textLeftSubTitle: 'totalBusinessTasks',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberBusinessTasks',
        rightDataTitleKey: '',
        leftCallback: commonSumData,
      },
      {
        activeLines: [{ dataKey: 'totalNumberUsersSetBusinessTasks', fill: colorsVar.yellowLine }],
        textTitle: '',
        textLeftSubTitle: 'amountUsersCreatedBusinessTasks',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberUsersSetBusinessTasks',
        rightDataTitleKey: '',
        leftCallback: arrayUniqueData,
      },
    ],
  },
  {
    mapChartData: [
      {
        activeLines: [{ dataKey: 'totalNumberMeetings', fill: colorsVar.pigeonBlueLine }],
        textTitle: 'businessMeetings',
        textLeftSubTitle: 'totalBusinessMeetings',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberMeetings',
        rightDataTitleKey: '',
        leftCallback: commonSumData,
      },
      {
        activeLines: [{ dataKey: 'totalNumberUsersSetMeetings', fill: colorsVar.purpleLine }],
        textTitle: '',
        textLeftSubTitle: 'amountUsersCreatedBusinessMeetings',
        textRightSubTitle: '',
        leftDataTitleKey: 'totalNumberUsersSetMeetings',
        rightDataTitleKey: '',
        leftCallback: arrayUniqueData,
      },
    ],
  },
  {
    mapChartData: [
      {
        activeLines: [
          { dataKey: 'totalUniqueExternalParticipants', fill: colorsVar.pearlLine },
          { dataKey: 'totalUniqueInternalParticipants', fill: colorsVar.brownLine },
        ],
        textTitle: 'totalAmountInvitedParticipants',
        textLeftSubTitle: 'totalUniqueInternalParticipants',
        textRightSubTitle: 'totalUniqueExternalParticipants',
        leftDataTitleKey: 'totalUniqueExternalParticipants',
        rightDataTitleKey: 'totalUniqueInternalParticipants',
        leftCallback: arrayUniqueData,
        rightCallback: arrayUniqueData,
      },
    ],
  },
];

export function arrayUniqueData(data: any, key: string) {
  const commonSum = data?.reduce((sum: any, item: any) => [...sum, ...item[key]], []);
  const uniqueData = commonSum?.reduce((unique: any, item: any) => {
    return unique.includes(item) ? unique : [...unique, item];
  }, []);
  return uniqueData?.length;
}
export function commonSumData(data: any, key: string) {
  return data?.reduce((sum: number, item: any) => sum + item[key], 0);
}

export const NUM_DAYS_SWITCH_TO_MONTHS = 46;
export const NUM_DAYS_SWITCH_TO_YEARS = 730;
export const SCALE_YEARS = 'years';
export const SCALE_MONTHS = 'month';

export const valuesDataKey: { [key: string]: string } = {
  totalActivities: 'totalNumberActiveUsers',
  uniqueVisitors: 'totalNumberUniqueActiveUsers',
};
