import React, { useMemo, useRef, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { FormattedMessage } from 'react-intl';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText, Spacer } from '@components/commons/commonsStyled';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';
import { IRenderLine } from 'interfaces/stats.interfaces';
import { valuesDataKey } from '../constants';
import { ReactComponent as InfoIcon } from '@assets/icons/info-circle.svg';
import TooltipInfo from './TooltipInfo';
import { useClickAway } from 'react-use';

interface LineChartReportsProps {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  isLoading: boolean;
  onlyOneLine?: boolean;
  textTitle?: string;
  textLeftSubTitle?: string;
  textRightSubTitle?: string;
  data: any;
  activeLines: IRenderLine[];
  valueLeftTitle?: string;
  valueRightTitle?: string;
}

export const LineChartReports = ({
  tooltip,
  isLoading,
  textTitle,
  textLeftSubTitle,
  textRightSubTitle,
  data,
  activeLines,
  valueLeftTitle,
  valueRightTitle,
  onlyOneLine,
}: LineChartReportsProps) => {
  const { isWideScreen, isMobile } = useWidthScreens();

  const [nameActiveButton, setNameActiveButton] = useState(textLeftSubTitle);
  const [isActive, setIsActive] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setTimeout(() => {
      setIsActive(false);
    }, 200);
  });

  const currentActiveLines = useMemo(() => {
    if (!onlyOneLine) {
      return activeLines;
    }
    if (!nameActiveButton) {
      return [];
    }

    return activeLines.filter((item) => item.dataKey === valuesDataKey[nameActiveButton]);
  }, [onlyOneLine, activeLines, nameActiveButton]);

  function getValueX(data: any) {
    if (!data || !data[0]) return 'date';
    if ('date' in data[0]) {
      return 'date';
    }
    if ('yearMonth' in data[0]) {
      return 'yearMonth';
    }
    if ('year' in data[0]) {
      return 'year';
    }
  }

  const CustomTick = ({ x, y, payload }: any) => {
    const text = payload.value.split('(');
    return (
      <g
        transform={`translate(${x},${y})
      
       `}
      >
        {text.map((line: any, index: number) => (
          <text key={index} x={0} y={index * 15} textAnchor="middle" fill={colorsVar.textColor} fontSize={11.5}>
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <ContentWrap>
      <LineText fontSize="16px" lineHeight="24px" bold="700" leftIndent={isMobile ? '41px' : '0px'}>
        <RenderWithCondition condition={!!textTitle}>
          <FormattedMessage id={textTitle} />
        </RenderWithCondition>
        <RenderWithCondition condition={!textTitle}>
          <Spacer px={isWideScreen ? 24 : 0} />
        </RenderWithCondition>
      </LineText>
      <ChartItem>
        <LabelChart>
          <RenderWithCondition condition={!!textLeftSubTitle}>
            <div className="flex gap-2 align-middle">
              <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="24px">
                <FormattedMessage id={textLeftSubTitle} />
              </LineText>
              <Amount
                active={onlyOneLine && nameActiveButton === textLeftSubTitle}
                isButton={onlyOneLine}
                onClick={() => setNameActiveButton(textLeftSubTitle)}
              >
                <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="22px">
                  {valueLeftTitle}
                </LineText>
              </Amount>
            </div>
          </RenderWithCondition>
          <RenderWithCondition condition={!!textRightSubTitle}>
            <div className="flex gap-2 relative ">
              <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="24px">
                <FormattedMessage id={textRightSubTitle} />
              </LineText>
              <Amount
                active={onlyOneLine && nameActiveButton === textRightSubTitle}
                isButton={onlyOneLine}
                onClick={() => setNameActiveButton(textRightSubTitle)}
              >
                <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="22px">
                  {valueRightTitle}
                </LineText>
              </Amount>
              <RenderWithCondition condition={onlyOneLine}>
                <div
                  className="self-center"
                  ref={ref}
                  onMouseEnter={() => setIsActive(true)}
                  onMouseLeave={() => setIsActive(false)}
                >
                  <InfoIcon />
                  <TooltipInfo isActive={isActive} />
                </div>
              </RenderWithCondition>
            </div>
          </RenderWithCondition>
        </LabelChart>
        <RenderWithCondition condition={!isLoading}>
          <LineStatChart
            dot={{ r: 3 }}
            fill={colorsVar.textColor}
            pointXaxis={getValueX(data)}
            data={data ?? []}
            renderLine={currentActiveLines}
            externalTooltip={tooltip}
            customTick={getValueX(data) === 'year' || data?.length < 6 ? CustomTick : undefined}
          />
        </RenderWithCondition>
        <LocalLoader condition={isLoading} size={25} />
      </ChartItem>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;
const LabelChart = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 60px;
  min-height: 12px;
  gap: 30px;
`;
const ChartItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 357px;
  background-color: ${colorsVar.white};
  padding: 3px 5px 5px 0;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
`;

const Amount = styled.div<{ active?: boolean; isButton?: boolean }>`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  border: 0.5px solid ${colorsVar.grey4};
  border-radius: 6px;
  background-color: ${colorsVar.grey1};
  padding: 0px 6px;
  height: 24px;
  min-width: 24px;
  gap: 10px;
  transition: all 0.3s ease-in-out;

  ${({ isButton }) =>
    isButton &&
    `
    &:hover {
    background-color: ${colorsVar.grey4};
  }
  cursor: pointer;
  border: 2px solid ${colorsVar.grey4};
  `}
  ${({ active }) =>
    active &&
    `
border: 2px solid ${colorsVar.greenLine};
transition: all 0.3s ease-in-out;
`}
`;
