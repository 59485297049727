import React, { ChangeEvent, useEffect, useState } from 'react';

import { colorsVar } from '@components/constants/colorVariables';
import { ReactComponent as SearchIcon } from '@assets/icons/search_grey.svg';
import { LocalLoader } from '@ui/loader/LocalLoader';
import styled from 'styled-components';
import { mobile, tablet } from '@components/constants/constants';

interface ISearch {
  handleSearch?: (value: string) => void;
  isLoading?: boolean;
  searchValue?: string;
  className?: string;
  classNameInput?: string;
}

const Search = ({ handleSearch, isLoading, searchValue, classNameInput, className, ...props }: ISearch) => {
  const [localSearchValue, setLocalSearchValue] = useState<string>(searchValue ?? '');

  useEffect(() => {
    if (searchValue !== undefined) {
      setLocalSearchValue(searchValue);
    }
  }, [searchValue]);

  return (
    <SearchWrap className={className} {...props}>
      <div className="flex w-full ">
        <div className="flex relative w-6 gap-3">
          <LocalLoader condition={isLoading ? isLoading : false} withoutBlur posY={'9px'} />
        </div>
        <input
          className={classNameInput}
          onChange={(event) => {
            handleSearch && handleSearch(event?.target?.value);
            setLocalSearchValue(event?.target?.value);
          }}
          value={localSearchValue}
        />
      </div>
      <SearchIcon />
    </SearchWrap>
  );
};

export default Search;

const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  width: 100%;
  min-width: 150px;
  flex-grow: 1;
  padding: 1px 18px 1px 7px;
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.grey1};
  border-radius: 10px;
  box-sizing: border-box;
  input {
    width: 100%;
    background: none;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: ${colorsVar.textPrimary};
  }
  svg {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: ${tablet}px) {
    height: 48px;
  }
  @media screen and (max-width: ${mobile}px) {
    height: 43px;
  }
`;
