class ColorVariables {
  redLine = '#FF3B30';
  blueLine = '#007AFF';
  greenLine = '#219653';
  yellowLine = '#FFB800';
  violetLine = '#8264FB';
  orangeLine = '#FB9231';
  pearlLine = '#2A5D70';
  brownLine = '#C09566';
  purpleLine = '#BF748E';
  pigeonBlueLine = '#60718C';
  violetHover = '#dfd8ff';

  white = '#FFFFFF';
  white80 = '#ffffff82';

  greyBase = '#f5f5f5';
  grey1 = '#E3E9E7';
  grey100 = '#e6e6e6';
  grey2 = '#bba5a5';
  grey3 = '#f5f9f8';
  grey300 = '#c7c7c7';
  grey4 = '#b3beb8';
  grey5 = '#ecf0ef';

  greenBase = '#297952';
  greenHover = '#33c37d';
  greenActive = '#185035';
  greenClose = '#5F6E67';
  greenScroll = '#297952b7';
  greenCalendar = '#a1b6ad';

  redDelete = '#CA504A';

  textPrimary = '#121212E5';
  textGrey60 = '#12121299';
  textGrey40 = '#1212124D';
  textColor = '#274335';

  shadowsTooltip = '#0f0f0f46';

  constructor() {}
}

export const colorsVar = new ColorVariables();
