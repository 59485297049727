import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import { ReactComponent as DownArrowIcon } from '@assets/icons/chevron_down_grey.svg';
import Search from '@ui/search/Search';
import CheckInputMod from '@ui/checkInput/CheckInputMod';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { mobile, tablet } from '@components/constants/constants';
import { uniqueId } from 'lodash';
import { useDispatch } from 'react-redux';
import { ExtendedCheckSubdivision, IDepartment, IDivisions } from 'interfaces/user.interfaces';
import { useClickAway } from 'react-use';
import styled, { keyframes } from 'styled-components';
import { LocalLoader } from '@ui/loader/LocalLoader';

export interface ExtendedCheck extends IDepartment {
  isActive?: boolean;
}

interface IDropDownReports {
  handleCheck: (value: Partial<IDivisions>) => void;
  textLabel: string;
  dropList: any[];
  checkedList: ExtendedCheckSubdivision[] | ExtendedCheck[];
  textHeader?: string;
  isOpenPopup?: boolean | null;
  handleSearch: (value: string) => void;
  isLoading: boolean;
  isLoadingDep?: boolean;
  searchValue?: string;
}

const DropDownReports = ({
  handleCheck,
  textLabel,
  dropList,
  checkedList,
  textHeader,
  isOpenPopup,
  handleSearch,
  isLoading,
  searchValue,
  isLoadingDep = false,
}: IDropDownReports) => {
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const dispatch = useDispatch();

  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpenDrop(false);
  });

  useEffect(() => {
    setOpenDrop(false);
  }, [isOpenPopup]);

  useEffect(() => {
    if (!openDrop) handleSearch('');
  }, [openDrop]);

  return (
    <DropList className="flex">
      <RenderWithCondition condition={!!textLabel}>
        <LineText fontSize="14px" bold="450" lineHeight="16px" color={colorsVar.textGrey60}>
          <FormattedMessage id={textLabel} />
        </LineText>
      </RenderWithCondition>
      <DropBase ref={ref}>
        <DropHeader
          onClick={() => {
            dropList.length && setOpenDrop(!openDrop);
          }}
          active={openDrop}
        >
          <LineTextHeader fontSize="16px" color={textHeader ? '' : colorsVar.textGrey40}>
            {textHeader ? textHeader : <FormattedMessage id="select" />}
          </LineTextHeader>

          <RenderWithCondition condition={!isLoadingDep && dropList?.length}>
            <DownArrowIcon />
          </RenderWithCondition>
          <RenderWithCondition condition={isLoadingDep}>
            <div className="flex relative w-4 gap-3 ">
              <LocalLoader condition={true} withoutBlur posY={'9px'} />
            </div>
          </RenderWithCondition>
        </DropHeader>

        <DropFieldWrap active={openDrop}>
          <DropField ref={ref}>
            <div className="flex h-11 px-2 py-1 w-full">
              <SearchCustom handleSearch={handleSearch} isLoading={isLoading} searchValue={searchValue} />
            </div>
            <div className="flex flex-col w-full">
              {!isLoading &&
                checkedList &&
                checkedList.map((item) => (
                  <ItemCheckLine key={uniqueId(item.id)}>
                    <CheckInputMod
                      changeEvent={() => {
                        handleCheck(item);
                      }}
                      isActive={item?.isActive ?? false}
                      isDisabled={false}
                    />
                    <LineText className="justify-center" fontSize="14px" lineHeight="16px">
                      {item.name}
                    </LineText>
                  </ItemCheckLine>
                ))}
            </div>
            <ScrolledField>
              {!isLoading &&
                dropList &&
                dropList.map((item) => (
                  <ItemCheckLine key={uniqueId(item.id)}>
                    <CheckInputMod
                      changeEvent={() => {
                        handleCheck(item);
                        setChecked(!checked);
                      }}
                      isActive={checkedList.some((i) => i.id === item.id) ?? false}
                      isDisabled={checkedList.some((i) => i.id === item.id) ?? false}
                    />
                    <LineText className="justify-center" fontSize="14px" lineHeight="16px">
                      {item.name}
                    </LineText>
                  </ItemCheckLine>
                ))}
            </ScrolledField>
          </DropField>
        </DropFieldWrap>
      </DropBase>
    </DropList>
  );
};

export default DropDownReports;

const DropList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: space-between;
  @media screen and (max-width: ${mobile}px) {
    flex-direction: column;
    gap: 8px;
  }
`;
const rotateArrowAnimationUp = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;
const rotateArrowAnimationDown = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
const DropHeader = styled.div<{ active: boolean }>`
  display: flex;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
  background-color: ${colorsVar.white};
  cursor: pointer;
  svg {
    transform: rotate(0deg);
    animation: ${({ active }) => {
        return active ? rotateArrowAnimationUp : rotateArrowAnimationDown;
      }}
      500ms linear forwards;
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }

  @media screen and (max-width: ${mobile}px) {
    height: 46px;
    flex: 1;
    width: 100%;
  }
`;
const DropBase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: ${tablet}px) {
    gap: 5px;
  }
`;
const DropField = styled.div`
  display: flex;
  gap: 7px;
  max-height: 280px;
  min-height: 280px;
  flex-direction: column;
  padding: 6px;
  border-radius: 10px;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
  background-color: ${colorsVar.white};
`;
const ScrolledField = styled.div`
  display: flex;
  width: 100%;
  max-height: 300px;
  flex-direction: column;

  align-items: center;
  overflow: auto;
`;
const ItemCheckLine = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  gap: 8px;
`;
const SearchCustom = styled(Search)`
  height: 35px;
`;
const LineTextHeader = styled(LineText)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
const DropFieldWrap = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.7s ease 0.5s;

  ${({ active }) =>
    active &&
    `
    max-height: 300px;
    transition: max-height 0.6s ease ;
  `}
`;
