import React, { useMemo } from 'react';
import {
  NUM_DAYS_SWITCH_TO_MONTHS,
  NUM_DAYS_SWITCH_TO_YEARS,
  arrayUniqueData,
  commonSumData,
} from '../components/screens/reports/constants';

import dayjs from 'dayjs';
import { formattedMonthTitle } from '@utils/formattedMonthTitle';
import { ICurrentPeriod } from 'interfaces/stats.interfaces';

export const useDataReports = (currentPeriod: ICurrentPeriod, receivedDataReports: any) => {
  const currentMapDataReports: any = useMemo(() => {
    const diff = currentPeriod?.endDate?.diff(currentPeriod?.startDate, 'days');
    const startYear = currentPeriod?.startDate?.year();
    const endYear = currentPeriod?.endDate?.year();
    if (diff < NUM_DAYS_SWITCH_TO_MONTHS) {
      return receivedDataReports?.map((item: any) => {
        return {
          ...item,
          date: dayjs(item.date).format('DD.MM.YY'),
          totalNumberUniqueActiveUsers: item?.totalNumberUniqueActiveUsers?.length,
          totalNumberUsersSetGoals: item?.totalNumberUsersSetGoals?.length,
          totalNumberUsersSetBusinessTasks: item?.totalNumberUsersSetBusinessTasks?.length,
          totalNumberUsersSetMeetings: item?.totalNumberUsersSetMeetings?.length,
          totalUniqueExternalParticipants: item?.totalUniqueExternalParticipants?.length,
          totalUniqueInternalParticipants: item?.totalUniqueInternalParticipants?.length,
        };
      });
    }
    if (diff > NUM_DAYS_SWITCH_TO_YEARS) {
      const diffYears = endYear - startYear + 1;
      const arrayDiffYears = diffYears ? Array.from(Array(diffYears).keys()) : [];

      const convertedYearsData = arrayDiffYears.map((item: any, index, arr) => {
        const currentYear = dayjs(currentPeriod?.startDate).add(index, 'year').year();
        const filteredYearData = receivedDataReports?.filter((item: any) => dayjs(item.date).year() === currentYear);
        return {
          year: `${dayjs(currentPeriod?.startDate).add(index, 'year').format('YYYY')} г.`,
          totalNumberUniqueActiveUsers: arrayUniqueData(filteredYearData, 'totalNumberUniqueActiveUsers'),
          totalNumberActiveUsers: commonSumData(filteredYearData, 'totalNumberActiveUsers'),
          totalNumberBusinessGoals: commonSumData(filteredYearData, 'totalNumberBusinessGoals'),
          totalNumberUsersSetGoals: arrayUniqueData(filteredYearData, 'totalNumberUsersSetGoals'),
          totalNumberBusinessTasks: commonSumData(filteredYearData, 'totalNumberBusinessTasks'),
          totalNumberUsersSetBusinessTasks: arrayUniqueData(filteredYearData, 'totalNumberUsersSetBusinessTasks'),
          totalNumberMeetings: commonSumData(filteredYearData, 'totalNumberMeetings'),
          totalNumberUsersSetMeetings: arrayUniqueData(filteredYearData, 'totalNumberUsersSetMeetings'),
          totalUniqueExternalParticipants: arrayUniqueData(filteredYearData, 'totalUniqueExternalParticipants'),
          totalUniqueInternalParticipants: arrayUniqueData(filteredYearData, 'totalUniqueInternalParticipants'),
        };
      });
      return convertedYearsData;
    }
    const firstMonth = currentPeriod?.startDate?.month();
    const lastMonth = currentPeriod?.endDate?.month();
    const diffMonth = (endYear - startYear) * 12 + (lastMonth - firstMonth + 1);
    const arrayDiffMonth = diffMonth ? Array.from(Array(diffMonth).keys()) : [];
    const convertedMonthData = arrayDiffMonth.map((item: any, index) => {
      const currentMonth = dayjs(currentPeriod?.startDate).add(index, 'month').month();
      const filteredMonthData = receivedDataReports?.filter((item: any) => dayjs(item.date).month() === currentMonth);
      return {
        yearMonth: formattedMonthTitle('ru', dayjs(currentPeriod?.startDate).add(index, 'month'), 'YY'),
        totalNumberUniqueActiveUsers: arrayUniqueData(filteredMonthData, 'totalNumberUniqueActiveUsers'),
        totalNumberActiveUsers: commonSumData(filteredMonthData, 'totalNumberActiveUsers'),
        totalNumberBusinessGoals: commonSumData(filteredMonthData, 'totalNumberBusinessGoals'),
        totalNumberUsersSetGoals: arrayUniqueData(filteredMonthData, 'totalNumberUsersSetGoals'),
        totalNumberBusinessTasks: commonSumData(filteredMonthData, 'totalNumberBusinessTasks'),
        totalNumberUsersSetBusinessTasks: arrayUniqueData(filteredMonthData, 'totalNumberUsersSetBusinessTasks'),
        totalNumberMeetings: commonSumData(filteredMonthData, 'totalNumberMeetings'),
        totalNumberUsersSetMeetings: arrayUniqueData(filteredMonthData, 'totalNumberUsersSetMeetings'),
        totalUniqueExternalParticipants: arrayUniqueData(filteredMonthData, 'totalUniqueExternalParticipants'),
        totalUniqueInternalParticipants: arrayUniqueData(filteredMonthData, 'totalUniqueInternalParticipants'),
      };
    });
    return convertedMonthData;
  }, [receivedDataReports]);

  return { currentMapDataReports };
};
