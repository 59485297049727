import React from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { LineText } from '@components/commons/commonsStyled';
import styled from 'styled-components';
import { mobile, tablet } from '@components/constants/constants';
import { FormattedMessage } from 'react-intl';

interface ITooltipInfo {
  isActive: boolean;
}

const TooltipInfo = ({ isActive }: ITooltipInfo) => {
  return (
    <Container active={isActive}>
      <div className="flex flex-col">
        <LineText fontSize="13px" color={colorsVar.textColor} bold={'400'} lineHeight="18px">
          <FormattedMessage id={'infoAboutActiveStatistic1'} />
        </LineText>
        <LineText fontSize="13px" color={colorsVar.textColor} bold={'500'} lineHeight="18px" alignText="center">
          <FormattedMessage id={'infoAboutActiveStatistic2'} />
        </LineText>
      </div>
    </Container>
  );
};

export default TooltipInfo;

const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 150px;
  bottom: 30px;
  width: 190px;
  border-radius: 10px;
  padding: 12px;
  background-color: ${colorsVar.white};
  scale: 0;
  transition: all 0.5s;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  ${({ active }) =>
    active &&
    `
  scale: 1;
  transition: all 0.5s;
  z-index: 2;
  `}
  @media screen and (max-width: ${tablet}px) {
    bottom: 40px;
  }
  @media screen and (max-width: ${mobile}px) {
    left: 50px;
    bottom: 40px;
  }
`;
