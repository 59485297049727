import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  NUM_DAYS_SWITCH_TO_MONTHS,
  NUM_DAYS_SWITCH_TO_YEARS,
  SCALE_MONTHS,
  SCALE_YEARS,
  mapChartsConfig,
} from './constants';
import { colorsVar } from '@components/constants/colorVariables';
import { useAppSelector } from '@store/hooks';
import { selectDeviceSlice } from '@store/device/device.slice';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';
import { useGetActiveUsersQuery, useGetStatisticReportsMutation } from '@store/statistic/statistic.api';
import { ControlBlock } from '@components/shared/controlBlock';
import { TooltipChart } from '../home/components/tooltips/TooltipChart';
import { LineText } from '@components/commons/commonsStyled';
import { ReactComponent as FilterIcon } from '@assets/icons/filter-set.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import dayjs from 'dayjs';
import { tablet } from '@components/constants/constants';
import { LineChartReports } from './components/LineChartReports';
import { ICurrentPeriod } from 'interfaces/stats.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { FiltersBlock } from './components/FiltersBlock';
import PopupModal from '@ui/modal/PopupModal';
import { formattedTooltip } from '../home/statsUtils';
import { formattedMonthTitle } from '@utils/formattedMonthTitle';
import { useDataReports } from '@hooks/useDataReports';

export const Reports = () => {
  const { width, isMobile } = useWidthScreens();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openFiltersBlockPopup, setFiltersBlockPopup] = useState<boolean | null>(false);
  const [activeUserParams, setActiveUserParams] = useState('');
  const [checkedIdsList, setIdsList] = useState<(string | undefined)[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<ICurrentPeriod>({} as ICurrentPeriod);

  const [getStatisticReports, statisticReportsResult] = useGetStatisticReportsMutation();

  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);

  const diff = useMemo(() => {
    return currentPeriod?.endDate?.diff(currentPeriod?.startDate, 'days');
  }, [currentPeriod]);

  const commonTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload?.length) {
      const subScalePeriod = diff > NUM_DAYS_SWITCH_TO_YEARS ? SCALE_YEARS : SCALE_MONTHS;
      const scalePeriod = diff < NUM_DAYS_SWITCH_TO_MONTHS ? '' : subScalePeriod;

      let textLabel = label;
      switch (scalePeriod) {
        case 'years':
          if (label.slice(0, 4) === currentPeriod?.startDate.format('YYYY')) {
            textLabel = `${label} (c ${currentPeriod?.startDate.format('DD.MM.YY')})`;
          }
          if (label.slice(0, 4) === currentPeriod?.endDate.format('YYYY')) {
            textLabel = `${label} (до ${currentPeriod?.endDate.format('DD.MM.YY')})`;
          }
          break;
        case 'month':
          if (label === formattedMonthTitle('ru', dayjs(currentPeriod?.startDate), 'YY')) {
            textLabel = `${label} (c ${currentPeriod?.startDate.format('DD.MM.YY')})`;
          }
          if (label === formattedMonthTitle('ru', dayjs(currentPeriod?.endDate), 'YY')) {
            textLabel = `${label} (до ${currentPeriod?.endDate.format('DD.MM.YY')})`;
          }
          break;
        default:
          textLabel = label;
          break;
      }

      return <TooltipChart label={textLabel} payload={payload} formattedTooltip={formattedTooltip} />;
    }
    return null;
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [isHiddenNavbar, width]);

  useEffect(() => {
    if (activeUserParams) {
      const newData = {
        params: activeUserParams,
        data: checkedIdsList,
      };
      getStatisticReports(newData);
    }
  }, [checkedIdsList, activeUserParams]);

  const responseStatisticReports = useMemo(() => {
    return statisticReportsResult.data;
  }, [statisticReportsResult.data]);

  const { currentMapDataReports } = useDataReports(currentPeriod, responseStatisticReports);

  return (
    <ScrollWrapper className="flex ">
      <ControlBlock title={'reports'} isAdmin={true} noSearch onlyTitle />
      <RenderWithCondition condition={!isMobile}>
        <FiltersBlock
          setActiveUserParams={setActiveUserParams}
          setCurrentPeriod={setCurrentPeriod}
          setIdsList={setIdsList}
        />
      </RenderWithCondition>
      <RenderWithCondition condition={isMobile}>
        <FilterButton onClick={() => setFiltersBlockPopup(true)}>
          <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="24px">
            <FormattedMessage id={'filter'} />
          </LineText>
          <FilterIcon />
        </FilterButton>
      </RenderWithCondition>
      <Content>
        <GrafBlock>
          {mapChartsConfig.map((item, index) => (
            <ChartWrap key={uuidv4()}>
              {item.mapChartData.map((itemChart) => (
                <LineChartReports
                  tooltip={commonTooltip}
                  isLoading={statisticReportsResult.isLoading || isLoading}
                  textTitle={itemChart.textTitle}
                  textLeftSubTitle={itemChart.textLeftSubTitle}
                  textRightSubTitle={itemChart?.textRightSubTitle}
                  data={currentMapDataReports}
                  activeLines={itemChart.activeLines}
                  valueLeftTitle={itemChart.leftCallback(responseStatisticReports, itemChart.leftDataTitleKey) ?? ''}
                  valueRightTitle={
                    (itemChart.rightCallback &&
                      itemChart.rightCallback(responseStatisticReports, itemChart.rightDataTitleKey)) ??
                    ''
                  }
                  key={uuidv4()}
                  onlyOneLine={itemChart?.onlyOneLine ?? false}
                />
              ))}
            </ChartWrap>
          ))}
        </GrafBlock>
      </Content>

      <PopupModal isShown={openFiltersBlockPopup} closeEvent={(v) => setFiltersBlockPopup(v)}>
        <ScrollPopup>
          <FiltersBlock
            setActiveUserParams={setActiveUserParams}
            setCurrentPeriod={setCurrentPeriod}
            setIdsList={setIdsList}
          />
        </ScrollPopup>
      </PopupModal>
    </ScrollWrapper>
  );
};

const ScrollWrapper = styled.div`
  flex-direction: column;
  position: relative;
  overflow-x: auto;
  @media screen and (max-width: 800px) {
    width: 100vw;
  }
`;
const ScrollPopup = styled.div`
  display: flex;
  overflow-y: auto;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    min-width: 650px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  gap: 7px;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1;
  padding: 9px;
  border-radius: 6px;
  background-color: ${colorsVar.white};
  -webkit-box-shadow: 3px 1px 21px 0px rgba(173, 180, 178, 0.74);
  box-shadow: 3px 1px 21px 0px rgba(173, 180, 178, 0.74);
  cursor: pointer;
`;

const ChartWrap = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 4%;
  position: relative;
  @media screen and (max-width: ${tablet}px) {
    flex-direction: column;
    width: 100%;
    gap: 0px;
  }
`;

const GrafBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 41px;
  padding: 25px 30px 50px 30px;
  @media screen and (max-width: 800px) {
    padding: 30px 16px;
  }
`;
