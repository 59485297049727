import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg';
import { FormattedMessage } from 'react-intl';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useLocation } from 'react-router-dom';
import Search from '@ui/search/Search';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { LineText } from '@components/commons/commonsStyled';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import './controlBlock.scss';
import styled from 'styled-components';

interface IControlBlock {
  openForm?: (arg: boolean) => void;
  openFilter?: (arg: boolean) => void;
  handleSearch?: (value: string) => void;
  title: string;
  isAdmin?: boolean;
  supervisor?: boolean;
  openTab?: boolean;
  textButton?: string;
  isLoading?: boolean;
  searchValue?: string;
  noSearch?: boolean;
  onlyTitle?: boolean;
}

export const ControlBlock: FC<IControlBlock> = ({
  openForm,
  openFilter,
  title,
  handleSearch,
  supervisor,
  isAdmin,
  isLoading,
  searchValue,
  textButton = 'addUser',
  noSearch = false,
  onlyTitle = false,
}) => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [value, setValue] = useState('');
  const location = useLocation();

  const { isMobile } = useWidthScreens();

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 760px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [location.pathname]);

  return (
    <>
      <RenderWithCondition condition={!isMobile}>
        <div className="control-wrap">
          <div className="group-btn">
            <RenderWithCondition condition={!supervisor}>
              <h1>
                <span>
                  <FormattedMessage id={title} />
                </span>
              </h1>
            </RenderWithCondition>
            <RenderWithCondition condition={!isWideScreen && !isAdmin && !noSearch}>
              <div className="group">
                <Search handleSearch={handleSearch && handleSearch} isLoading={isLoading} searchValue={searchValue} />
              </div>
            </RenderWithCondition>
          </div>
          <div className="group-btn">
            <div className="btn-btn">
              <RenderWithCondition condition={!supervisor && !onlyTitle}>
                <button onClick={() => openForm && openForm(true)}>
                  <FormattedMessage id={textButton} />
                </button>
              </RenderWithCondition>

              <RenderWithCondition condition={supervisor}>
                <h1>
                  <span>
                    <FormattedMessage id={title} />
                  </span>
                </h1>
              </RenderWithCondition>
            </div>

            <div className="group">
              <RenderWithCondition condition={(isWideScreen || isAdmin) && !noSearch}>
                <Search
                  handleSearch={handleSearch}
                  isLoading={isLoading}
                  searchValue={searchValue}
                  className="h-[43px]"
                />
              </RenderWithCondition>
              <RenderWithCondition condition={!isAdmin}>
                <div className="filter-btn">
                  <button className="filter-button" onClick={() => openFilter && openFilter(true)}>
                    <FilterIcon />
                    <span>
                      <FormattedMessage id="filter" />
                    </span>
                  </button>
                </div>
              </RenderWithCondition>
            </div>
          </div>
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={isMobile}>
        <ControlWrap className="flex flex-col">
          <div className={`flex flex-col gap-2 ${isMobile ? 'pl-12' : ''}`}>
            <LineText fontSize="22px">
              <FormattedMessage id={title} />
            </LineText>
            <RenderWithCondition condition={!noSearch}>
              <Search handleSearch={handleSearch} isLoading={isLoading} searchValue={searchValue} />
            </RenderWithCondition>
            <RenderWithCondition condition={noSearch && !onlyTitle}>
              <ButtonMain clickEvent={() => openForm && openForm(true)} buttonText={textButton} />
            </RenderWithCondition>
          </div>
          <RenderWithCondition condition={!noSearch}>
            <>
              <ButtonMain clickEvent={() => openForm && openForm(true)} buttonText={textButton} />
              <RenderWithCondition condition={!isAdmin}>
                <ButtonMain clickEvent={() => openFilter?.(true)} buttonText={'filter'} />
              </RenderWithCondition>
            </>
          </RenderWithCondition>
        </ControlWrap>
      </RenderWithCondition>
    </>
  );
};

const ControlWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
  padding: 16px;
  box-sizing: border-box;
`;
