import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import dayjs from 'dayjs';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '@assets/icons/chevron-right.svg';
import Calendar from 'react-calendar';
import { useClickAway } from 'react-use';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import 'react-calendar/dist/Calendar.css';
import './selectDateReports.scss';
import { ValuePiece } from '@components/shared/selectDate/SelectDateMonth';
import { formattedMonthTitle } from '@utils/formattedMonthTitle';
import styled from 'styled-components';
import { colorsVar } from '@components/constants/colorVariables';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText, Spacer } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { mobile, tablet } from '@components/constants/constants';

const customPrev2Label = <div className="prev2Label"></div>;

interface ISelectDateReports {
  id: string;
  type: string;
  value: string;
  onChange: (value: dayjs.Dayjs) => void;
  position: CalendarPosition;
  textLabel: string;
}

export const SelectDateReports = ({ onChange, value, id, position, textLabel }: ISelectDateReports) => {
  const [currentDate, setCurrentDate] = useState(value);
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());

  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpenDrop(false);
  });

  useEffect(() => {
    setCurrentDate(value ?? '');
  }, [value]);

  const handlePickDate = (event: Value | ValuePiece) => {
    const currDate = dayjs(event as Date | null).format('DD.MM.YY');
    setCurrentDate(currDate);
    onChange(dayjs(event as Date | null));
  };

  return (
    <DropList className="flex">
      <RenderWithCondition condition={!!textLabel}>
        <LineText fontSize="14px" bold="450" lineHeight="16px" color={colorsVar.textGrey60}>
          <FormattedMessage id={textLabel} />
        </LineText>
      </RenderWithCondition>
      <RenderWithCondition condition={!textLabel}>
        <Spacer px={16} />
      </RenderWithCondition>
      <DropBase ref={ref}>
        <DropHeader
          onClick={(e) => {
            setOpenDrop(!openDrop);
          }}
          active={openDrop}
        >
          <LineText fontSize="16px">{currentDate}</LineText>
          <CalendarIcon />
        </DropHeader>

        <DropFieldWrap active={openDrop}>
          <DropField ref={ref}>
            <div className="calendar-reports">
              <Calendar
                formatMonthYear={formattedMonthTitle}
                navigationAriaLabel={'none'}
                tileClassName="content"
                prev2Label={customPrev2Label}
                next2Label={''}
                prevLabel={<LeftIcon fill={colorsVar.greenBase} />}
                nextLabel={<RightIcon fill={colorsVar.greenBase} />}
                onChange={handlePickDate}
                formatLongDate={(locale, date) => dayjs(locale).format('DD-MM-YYYY')}
                defaultView="month"
                value={startDate}
              />
            </div>
          </DropField>
        </DropFieldWrap>
      </DropBase>
    </DropList>
  );
};

const DropList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: space-between;
  @media screen and (max-width: ${mobile}px) {
    flex-direction: column;
    gap: 8px;
  }
`;
const DropHeader = styled.div<{ active: boolean }>`
  display: flex;
  height: 100%;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
  background-color: ${colorsVar.white};
  cursor: pointer;

  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }

  @media screen and (max-width: ${mobile}px) {
    height: 46px;
    flex: 1;
    width: 100%;
  }
`;
const DropBase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: ${tablet}px) {
    gap: 5px;
  }
  @media screen and (max-width: ${mobile}px) {
    /* height: 46px;
    flex: 1;
    width: 100%; */
  }
`;
const DropField = styled.div`
  display: flex;
  gap: 7px;
  max-height: 280px;
  min-height: 230px;
  flex-direction: column;
  padding: 6px;
  border-radius: 10px;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
  background-color: ${colorsVar.white};
  height: 350px;
`;
const DropFieldWrap = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease 0.5s;

  ${({ active }) =>
    active &&
    `
    max-height: 300px;
    transition: max-height 0.6s ease ;
  `}
`;
